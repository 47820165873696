import { gql } from '@apollo/client/core'
import { ERROR_NOT_FOUND, apolloClient } from '@/apis/graphql/client'
import { getParentReadNotificationById, getParentReadNotifications } from '@/apis/graphql/query/queries'
import {
  type GetParentReadNotificationsResponse,
  type GetParentReadNotificationsQuery,
  type RegisterReadNotificationForParentMutation,
  type RegisterReadNotificationParentInput,
  type GetParentReadNotificationByIdQuery,
  type GetParentReadNotificationByIdResponse
} from '@/types/codegen/schema'
import { registerReadNotificationForParent } from '@/apis/graphql/query/mutations'

export namespace ParentReadNotificationRepoResponse {
  export type fetchByParentId = GetParentReadNotificationsResponse
  export type fetchById = GetParentReadNotificationByIdResponse
}

export const parentReadNotificationRepo = {
  fetchById: async (parentReadNotificationId: number) => {
    const { data } = await apolloClient.query<GetParentReadNotificationByIdQuery>({
      query: gql(getParentReadNotificationById),
      variables: {
        parentReadNotificationId
      }
    })
    if (!data || !data.getParentReadNotificationById) throw ERROR_NOT_FOUND
    return data.getParentReadNotificationById
  },
  fetchByParentId: async (parentId: number) => {
    const { data } = await apolloClient.query<GetParentReadNotificationsQuery>({
      query: gql(getParentReadNotifications),
      variables: {
        parentId
      }
    })
    if (!data || !data.getParentReadNotifications) throw ERROR_NOT_FOUND
    return data.getParentReadNotifications
  },
  read: async (input: RegisterReadNotificationParentInput) => {
    await apolloClient.mutate<RegisterReadNotificationForParentMutation>({
      mutation: gql(registerReadNotificationForParent),
      variables: { input }
    })
  }
}
