import { gql } from '@apollo/client/core'
import { changeParentOwnPassword } from '@/apis/graphql/query/mutations'
import { getParentEmail, getParentLine, getSignedInParent } from '@/apis/graphql/query/queries'
import { type Pickup } from '@/types'
import {
  type ChangeParentOwnPasswordMutation,
  type GetParentEmailQuery,
  type GetParentEmailResponse,
  type GetParentLineQuery,
  type GetParentLineResponse,
  type GetSignedInParentQuery,
  type GetSignedInParentResponse,
  type OwnPasswordChangeInput
} from '@/types/codegen/schema'
import { ERROR_NOT_FOUND, apolloClient } from '@/apis/graphql/client'
export namespace ParentRepoResponse {
  export type FetchSignedIn = GetSignedInParentResponse
  export type FetchEmails = Pickup<GetParentEmailResponse, 'emails'>
  export type FetchLines = Pickup<GetParentLineResponse, 'lines'>
}

export const parentRepo = {
  async fetchSignedIn(username: string) {
    const { data } = await apolloClient.query<GetSignedInParentQuery>({
      query: gql(getSignedInParent),
      variables: {
        username
      }
    })

    if (!data || !data.getParentByUsername) throw ERROR_NOT_FOUND
    return data.getParentByUsername
  },

  /* 保護者のメールアドレス取得 */
  async fetchEmails(id: number) {
    const { data } = await apolloClient.query<GetParentEmailQuery>({
      query: gql(getParentEmail),
      variables: {
        id
      }
    })

    if (!data || !data.getParentById) throw ERROR_NOT_FOUND
    return data.getParentById.emails || []
  },

  /* 保護者のLINE情報取得 */
  async fetchLines(id: number) {
    const { data } = await apolloClient.query<GetParentLineQuery>({
      query: gql(getParentLine),
      variables: {
        id
      }
    })

    if (!data || !data.getParentById) throw ERROR_NOT_FOUND
    return data.getParentById.lines || []
  },

  async changePassword(input: OwnPasswordChangeInput) {
    const { data } = await apolloClient.mutate<ChangeParentOwnPasswordMutation>({
      mutation: gql(changeParentOwnPassword),
      variables: { input }
    })
    if (!data || !data.changeParentOwnPassword) throw ERROR_NOT_FOUND
    return data.changeParentOwnPassword
  }
}
