import type { MutationTree, ActionTree } from 'vuex'
import { type ParentEmail, type State } from '@/parent/store/userStateTypes'

export const state = (): ParentEmail => ({
  __typename: 'ParentEmail',
  id: 0,
  emailAddress: '',
  emailOrder: 0,
  memo: '',
  notificationPermission: false,
  roomNotificationPermission: false
})

export const mutations: MutationTree<ParentEmail> = {
  set(s, parentEmail: Partial<ParentEmail>) {
    Object.assign(s, { ...parentEmail })
  }
}

export const actions: ActionTree<ParentEmail, State> = {
  set({ commit }, data: Partial<ParentEmail>) {
    commit('set', data)
  },
  reset({ commit }) {
    commit('set', {
      __typename: 'ParentEmail',
      id: 0,
      emailAddress: '',
      emailOrder: 0,
      memo: '',
      notificationPermission: false,
      roomNotificationPermission: false
    })
  }
}
