import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export var FooterSp = function FooterSp() {
  return import('../../parent/fragments/FooterSp.vue' /* webpackChunkName: "components/footer-sp" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var KomaAbsenceRequestCalendarDialog = function KomaAbsenceRequestCalendarDialog() {
  return import('../../parent/fragments/KomaAbsenceRequestCalendarDialog.vue' /* webpackChunkName: "components/koma-absence-request-calendar-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var KomaAddRequestCalendarDialog = function KomaAddRequestCalendarDialog() {
  return import('../../parent/fragments/KomaAddRequestCalendarDialog.vue' /* webpackChunkName: "components/koma-add-request-calendar-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var KomaTransferRequestCalendarDialog = function KomaTransferRequestCalendarDialog() {
  return import('../../parent/fragments/KomaTransferRequestCalendarDialog.vue' /* webpackChunkName: "components/koma-transfer-request-calendar-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SideMenuPc = function SideMenuPc() {
  return import('../../parent/fragments/SideMenuPc.vue' /* webpackChunkName: "components/side-menu-pc" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AimAtReportCard = function AimAtReportCard() {
  return import('../../parent/fragments/aim/AimAtReportCard.vue' /* webpackChunkName: "components/aim-at-report-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudyInfoCard = function StudyInfoCard() {
  return import('../../parent/fragments/aim/StudyInfoCard.vue' /* webpackChunkName: "components/study-info-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DailyCalendar = function DailyCalendar() {
  return import('../../parent/fragments/calendar/DailyCalendar.vue' /* webpackChunkName: "components/daily-calendar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var KomaDetailBottomDrawer = function KomaDetailBottomDrawer() {
  return import('../../parent/fragments/calendar/KomaDetailBottomDrawer.vue' /* webpackChunkName: "components/koma-detail-bottom-drawer" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var MonthlyCalendar = function MonthlyCalendar() {
  return import('../../parent/fragments/calendar/MonthlyCalendar.vue' /* webpackChunkName: "components/monthly-calendar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TabItemKoma = function TabItemKoma() {
  return import('../../parent/fragments/calendar/TabItemKoma.vue' /* webpackChunkName: "components/tab-item-koma" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OriginalExams = function OriginalExams() {
  return import('../../parent/fragments/grades/OriginalExams.vue' /* webpackChunkName: "components/original-exams" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SchoolExams = function SchoolExams() {
  return import('../../parent/fragments/grades/SchoolExams.vue' /* webpackChunkName: "components/school-exams" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ContactForm = function ContactForm() {
  return import('../../parent/fragments/index/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var CalendarCard = function CalendarCard() {
  return import('../../components/CalendarCard.vue' /* webpackChunkName: "components/calendar-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Headline = function Headline() {
  return import('../../components/Headline.vue' /* webpackChunkName: "components/headline" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ListCard = function ListCard() {
  return import('../../components/ListCard.vue' /* webpackChunkName: "components/list-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var LoadingBar = function LoadingBar() {
  return import('../../components/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ProgressBar = function ProgressBar() {
  return import('../../components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Snackbar = function Snackbar() {
  return import('../../components/Snackbar.vue' /* webpackChunkName: "components/snackbar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudentTextbookCard = function StudentTextbookCard() {
  return import('../../components/StudentTextbookCard.vue' /* webpackChunkName: "components/student-textbook-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudyPlanCalendar = function StudyPlanCalendar() {
  return import('../../components/StudyPlanCalendar.vue' /* webpackChunkName: "components/study-plan-calendar" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudyPlanCalendarKoma = function StudyPlanCalendarKoma() {
  return import('../../components/StudyPlanCalendarKoma.vue' /* webpackChunkName: "components/study-plan-calendar-koma" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var StudyPlanTextbookCard = function StudyPlanTextbookCard() {
  return import('../../components/StudyPlanTextbookCard.vue' /* webpackChunkName: "components/study-plan-textbook-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AlertDialog = function AlertDialog() {
  return import('../../components/dialogs/AlertDialog.vue' /* webpackChunkName: "components/alert-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var ConfirmDialog = function ConfirmDialog() {
  return import('../../components/dialogs/ConfirmDialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DateForm = function DateForm() {
  return import('../../components/forms/DateForm.vue' /* webpackChunkName: "components/date-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var InputForm = function InputForm() {
  return import('../../components/forms/InputForm.vue' /* webpackChunkName: "components/input-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SearchForm = function SearchForm() {
  return import('../../components/forms/SearchForm.vue' /* webpackChunkName: "components/search-form" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var SelectBox = function SelectBox() {
  return import('../../components/forms/SelectBox.vue' /* webpackChunkName: "components/select-box" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var TextArea = function TextArea() {
  return import('../../components/forms/TextArea.vue' /* webpackChunkName: "components/text-area" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var KomaBtn = function KomaBtn() {
  return import('../../components/komaRequest/KomaBtn.vue' /* webpackChunkName: "components/koma-btn" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var NavigationDrawerProfile = function NavigationDrawerProfile() {
  return import('../../components/profiles/NavigationDrawerProfile.vue' /* webpackChunkName: "components/navigation-drawer-profile" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Profile = function Profile() {
  return import('../../components/profiles/Profile.vue' /* webpackChunkName: "components/profile" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var Circular = function Circular() {
  return import('../../components/scrollContainer/Circular.vue' /* webpackChunkName: "components/circular" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PullToAndPaginationContainer = function PullToAndPaginationContainer() {
  return import('../../components/scrollContainer/PullToAndPaginationContainer.vue' /* webpackChunkName: "components/pull-to-and-pagination-container" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var AddKoma = function AddKoma() {
  return import('../../components/komaRequest/add/AddKoma.vue' /* webpackChunkName: "components/add-koma" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var KomaAddRequestListCard = function KomaAddRequestListCard() {
  return import('../../components/komaRequest/add/komaAddRequestListCard.vue' /* webpackChunkName: "components/koma-add-request-list-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var DestinationKoma = function DestinationKoma() {
  return import('../../components/komaRequest/transfer/DestinationKoma.vue' /* webpackChunkName: "components/destination-koma" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var KomaTransferRequestListCard = function KomaTransferRequestListCard() {
  return import('../../components/komaRequest/transfer/KomaTransferRequestListCard.vue' /* webpackChunkName: "components/koma-transfer-request-list-card" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var OriginKoma = function OriginKoma() {
  return import('../../components/komaRequest/transfer/OriginKoma.vue' /* webpackChunkName: "components/origin-koma" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PullToContainer = function PullToContainer() {
  return import('../../components/scrollContainer/pullTo/PullToContainer.vue' /* webpackChunkName: "components/pull-to-container" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};
export var PullToLoading = function PullToLoading() {
  return import('../../components/scrollContainer/pullTo/PullToLoading.vue' /* webpackChunkName: "components/pull-to-loading" */).then(function (c) {
    return wrapFunctional(c.default || c);
  });
};

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options;
  }
  var propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {});
  return {
    render: function render(h) {
      var attrs = {};
      var props = {};
      for (var key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key];
        } else {
          attrs[key] = this.$attrs[key];
        }
      }
      return h(options, {
        on: this.$listeners,
        attrs: attrs,
        props: props,
        scopedSlots: this.$scopedSlots
      }, this.$slots.default);
    }
  };
}