import { type Middleware } from '@nuxt/types'
import { Auth } from '@aws-amplify/auth'
import { parentRepo } from '@/repos/parent'
import { jukuRepo } from '@/repos/juku'
import { jukuConfigRepo } from '@/repos/jukuConfig'
import { PREFIX } from '@/parent/store/user'

const EXCLUDED_NAMES = ['sign-in', 'sign-up']

export default <Middleware>async function ({ route, store, redirect, $logger, $gtag }) {
  if (EXCLUDED_NAMES.includes(route.name as string)) return
  if (route.name === 'line-link-sign-in') return

  try {
    const pattern = `^${PREFIX}`
    // ユーザーをセット
    const { username } = await Auth.currentAuthenticatedUser()
    const usernameRemovedPrefix = (username as string).replace(new RegExp(pattern), '')
    const user = await parentRepo.fetchSignedIn(usernameRemovedPrefix)
    store.commit('user/set', user)
    // jukuConfigをセット
    const jukuConfig = await jukuConfigRepo.fetch(user.jukuId)
    store.commit('jukuConfig/set', jukuConfig)

    // sentryにusernameを送る
    $logger.identify(user.username, {
      id: user.id,
      username: user.username,
      jukuId: user.jukuId
    })
    // NOTE: googleAnalyticsにjuku_codeを送信
    if (process.env.STAGE_NAME !== 'local') {
      const juku = await jukuRepo.fetch(user.jukuId)
      $gtag('set', 'user_properties', { juku_code: juku.jukuCode })
    }
  } catch (err) {
    console.error(err)
    redirect('/sign-in')
  }
}
