import { gql } from '@apollo/client/core'
import { apolloClient, ERROR_NOT_FOUND } from '@/apis/graphql/client'
import { getJukuById } from '@/apis/graphql/query/queries'
import { type GetJukuByIdQuery, type GetJukuByIdResponse } from '@/types/codegen/schema'

export namespace JukuRepoResponse {
  export type Fetch = GetJukuByIdResponse
}

export const jukuRepo = {
  fetch: async (jukuId: number) => {
    const { data } = await apolloClient.query<GetJukuByIdQuery>({
      variables: { jukuId },
      query: gql(getJukuById)
    })
    if (!data) throw ERROR_NOT_FOUND
    console.debug('getJukuById', data.getJukuById)
    return { ...data.getJukuById }
  }
}
