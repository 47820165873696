export const getJukuById = /* GraphQL */ `
  query getJukuById($jukuId: Int!) {
    getJukuById(jukuId: $jukuId) {
      id
      corporateName
      jukuName
      jukuCode
    }
  }
`

export const getJukuConfigByJukuId = /* GraphQL */ `
  query getJukuConfigByJukuId($jukuId: Int!) {
    getJukuConfigByJukuId(jukuId: $jukuId) {
      id
      jukuId
      promotionPlanDate
      currentFiscalYear
      lineLinkFlag
      parentContactFlag
    }
  }
`
/** ログイン画面でサインインした保護者の情報を取得 */
export const getSignedInParent = /* GraphQL */ `
  query getSignedInParent($username: String!) {
    getParentByUsername(username: $username) {
      id
      jukuId
      userId # pw変更時必要
      username
      changedPasswordFlag
      name
      furigana
      gender
      studentId
    }
  }
`
/** 保護者のメールアドレスを取得 */
export const getParentEmail = /* GraphQL */ `
  query getParentEmail($id: Int!) {
    getParentById(id: $id) {
      id
      emails {
        id
        emailAddress
        emailOrder
        memo
        notificationPermission
        roomNotificationPermission
      }
    }
  }
`
/** 保護者のライン情報を取得 */
export const getParentLine = /* GraphQL */ `
  query getParentLine($id: Int!) {
    getParentById(id: $id) {
      id
      lines {
        id
        lineUid
        lineOrder
      }
    }
  }
`
/** ログイン画面でサインインした生徒の情報を取得 */
export const getSignedInStudent = /* GraphQL */ `
  query getSignedInStudent($username: String!) {
    getStudentByUsername(username: $username) {
      id
      jukuId
      userId
      username
      changedPasswordFlag
      name
      furigana
      gender
      semester {
        semesterName
        id
      }
      username
      latestLoginInfo {
        currentStatus
        loggedInAt
        loggedOutAt
      }
      studentGrade {
        grade
        gradeOrder
        id
      }
      course {
        color
        courseId
        courseName
      }
    }
  }
`
/** アカウントページで表示する生徒情報を取得する */
export const getStudent = /* GraphQL */ `
  query getStudent($id: Int!) {
    getStudent(id: $id) {
      id
      username
      name
      username
      latestLoginInfo {
        currentStatus
        loggedInAt
        loggedOutAt
      }
      classroom {
        id
        classroomName
        emailAddress
        phoneNumber
      }
      school {
        schoolName
      }
      semester {
        semesterName
        id
      }
      studentGrade {
        grade
        gradeOrder
        id
      }
      course {
        color
        courseId
        courseName
      }
    }
  }
`

/** 生徒に紐づく教材一覧を取得する */
// TODO: 教材スピード 新スキーマ対応
// export const getStudentTextbooksByStudentId = /* GraphQL */ `
//   query getStudentTextbooksByStudentId($studentId: Int!, $options: TextbookSearchOptions!) {
//     getStudentTextbooksByStudentId(studentId: $studentId, options: $options) {
//       id
//       textbookName
//       importantFlag
//       studentGrade {
//         id
//         grade
//         gradeOrder
//       }
//       semester {
//         id
//         semesterName
//       }
//       subject {
//         id
//         subjectName
//         categoryTypes {
//           textbook {
//             id
//           }
//         }
//         subjectArea {
//           id
//           subjectAreaName
//           subjects {
//             id
//             subjectName
//           }
//         }
//       }
//       # 進捗
//       targetLap
//       progressUnitName

//       totalTextbookStudyRanges {
//         startValue
//         endValue
//       }

//       textbookStudyComposition {
//         totalTextbookStudy {
//           id
//           studentTextbookId
//           totalTextbookStudyPeriods {
//             startDate
//             endDate
//           }
//           totalTextbookProgresses {
//             progressUnit {
//               value
//             }
//             progressLap
//             progressOwner
//           }
//         }
//       }
//       schoolLessonProgresses {
//         progressUnit {
//           value
//         }
//         isFinishedSchoolLesson
//         progressOwner
//       }
//     }
//   }
// `
/** コマ一覧取得(保護者用) */
export const getGuidanceDatesByStudentIdForParent = /* GraphQL */ `
  query getGuidanceDatesByStudentIdForParent($studentId: Int!, $startDate: String!, $endDate: String!) {
    getGuidanceDatesByStudentId(studentId: $studentId, startDate: $startDate, endDate: $endDate) {
      date
      loginInfos {
        loggedInAt
        loggedOutAt
      }
      guidanceKomas {
        id
        komaDate
        subjects {
          id
          subjectName
          subjectOrder
        }
        komaTemplate {
          komaName
          komaOrder
          startedTime
          finishedTime
        }
        attendanceStatus
        transferStartedDate
        transferFinishedDate
        transferOriginGuidanceKoma {
          komaDate
          komaTemplate {
            komaName
          }
        }
      }
    }
  }
`
/**
 * コマ一覧取得(生徒用)
 * 保護者向け指導報告をレスポンスに含めない
 */
export const getGuidanceDatesByStudentIdForStudent = /* GraphQL */ `
  query getGuidanceDatesByStudentIdForStudent($studentId: Int!, $startDate: String!, $endDate: String!) {
    getGuidanceDatesByStudentId(studentId: $studentId, startDate: $startDate, endDate: $endDate) {
      date
      loginInfos {
        loggedInAt
        loggedOutAt
      }
      guidanceKomas {
        id
        komaDate
        komaTemplate {
          komaName
          komaOrder
          startedTime
          finishedTime
        }
        attendanceStatus
        transferStartedDate
        transferFinishedDate
        subjects {
          id
          subjectName
          subjectOrder
        }
      }
    }
  }
`
export const getParentReadNotificationById = /* GraphQL */ `
  query getParentReadNotificationById($parentReadNotificationId: Int!) {
    getParentReadNotificationById(parentReadNotificationId: $parentReadNotificationId) {
      id
      readDate
      notification {
        id
        sendDate
        subject
        content
      }
    }
  }
`
export const getParentReadNotifications = /* GraphQL */ `
  query getParentReadNotifications($parentId: Int!) {
    getParentReadNotifications(parentId: $parentId) {
      id
      readDate
      notification {
        sendDate
        subject
      }
    }
  }
`
export const getStudentReadNotificationById = /* GraphQL */ `
  query getStudentReadNotificationById($studentReadNotificationId: Int!) {
    getStudentReadNotificationById(studentReadNotificationId: $studentReadNotificationId) {
      id
      readDate
      notification {
        id
        sendDate
        subject
        content
      }
    }
  }
`
export const getStudentReadNotifications = /* GraphQL */ `
  query getStudentReadNotifications($studentId: Int!) {
    getStudentReadNotifications(studentId: $studentId) {
      id
      readDate
      notification {
        sendDate
        subject
      }
    }
  }
`

/** 教室一覧取得 */
export const getClassroomsByJukuId = /* GraphQL */ `
  query getClassroomsByJukuId($jukuId: Int!) {
    getClassroomsByJukuId(jukuId: $jukuId) {
      id
      classroomName
    }
  }
`

/**
 * 学校の定期試験
 */
export const getSchoolExamsByStudentId = /* GraphQL */ `
  query getSchoolExamsByStudentId($studentId: Int!, $examSearchOptions: ExamSearchOptions!) {
    getSchoolExamsByStudentId(studentId: $studentId, examSearchOptions: $examSearchOptions) {
      id
      examName
      examStartDate
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      # 全科目の得合計と満点合計
      totalScorePoint
      totalPerfectPoint
    }
  }
`

export const getSchoolExamById = /* GraphQL */ `
  query getSchoolExamById($id: Int!, $studentId: Int) {
    getSchoolExamById(id: $id, studentId: $studentId) {
      id
      examName
      examStartDate
      examEndDate
      examSubjects {
        ... on DefaultSchoolExamSubject {
          schoolExamSubjectId
          schoolScoreSubjectId
          examSubjectInfo {
            id
            displayedSubjectName
            subject {
              subjectName
              id
            }
          }
          examDate
          examStatus
          # 得点
          scorePoint {
            value
            publicationFlag
          }
          scorePerfectPoint {
            value
            publicationFlag
          }
          scoreTargetPoint {
            value
            publicationFlag
          }
          scoreAveragePoint {
            value
            publicationFlag
          }
          # 評定
          evaluationPoint {
            value
            publicationFlag
          }
          evaluationMaxPoint {
            value
            publicationFlag
          }
          # 順位
          rank {
            value
            publicationFlag
          }
          rankOverall {
            value
            publicationFlag
          }
        }
        ... on AdditionalSchoolExamSubject {
          schoolScoreSubjectId
          examSubjectInfo {
            id
            displayedSubjectName
            subject {
              subjectName
              id
            }
          }
          examDate
          examStatus
          # 得点
          scorePoint {
            value
            publicationFlag
          }
          scorePerfectPoint {
            value
            publicationFlag
          }
          scoreTargetPoint {
            value
            publicationFlag
          }
          scoreAveragePoint {
            value
            publicationFlag
          }
          # 評定
          evaluationPoint {
            value
            publicationFlag
          }
          evaluationMaxPoint {
            value
            publicationFlag
          }
          # 順位
          rank {
            value
            publicationFlag
          }
          rankOverall {
            value
            publicationFlag
          }
        }
      }
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      # 全科目の得合計と満点合計
      totalScorePoint
      totalPerfectPoint
    }
  }
`

/**
 * オリジナル定期試験
 */
export const getOriginalExamsByStudentId = /* GraphQL */ `
  query getOriginalExamsByStudentId($studentId: Int!) {
    getOriginalExamsByStudentId(studentId: $studentId) {
      id
      examName
      examStartDate
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      # 全科目の得合計と満点合計
      totalScorePoint
      totalPerfectPoint
    }
  }
`

export const getOriginalExamById = /* GraphQL */ `
  query getOriginalExamById($id: Int!) {
    getOriginalExamById(id: $id) {
      id
      examName
      examStartDate
      examEndDate
      examSubjects {
        originalExamSubjectId
        examSubjectInfo {
          id
          displayedSubjectName
          subject {
            subjectName
            id
          }
        }
        examStatus
        examDate
        # 得点
        scorePoint {
          value
          publicationFlag
        }
        scorePerfectPoint {
          value
          publicationFlag
        }
        scoreTargetPoint {
          value
          publicationFlag
        }
        scoreAveragePoint {
          value
          publicationFlag
        }
        # 評定
        evaluationPoint {
          value
          publicationFlag
        }
        evaluationMaxPoint {
          value
          publicationFlag
        }
        # 順位
        rank {
          value
          publicationFlag
        }
        rankOverall {
          value
          publicationFlag
        }
      }
      studentGrade {
        id
        grade
        gradeOrder
      }
      semester {
        id
        semesterName
      }
      # 全科目の得合計と満点合計
      totalScorePoint
      totalPerfectPoint
    }
  }
`

export const getOptionsForExam = /* GraphQL */ `
  query getOptionsForExam($jukuId: Int!, $studentId: Int!) {
    getOptionsForExam(jukuId: $jukuId, studentId: $studentId) {
      studentGrades {
        id
        grade
        gradeOrder
      }
      semesters {
        id
        semesterName
      }
      subjectAreas {
        id
        subjectAreaName
        subjects {
          id
          subjectName
        }
      }
    }
  }
`

/**
 * 教科書登録時フォームに必要な選択肢をまとめて取得
 */
export const getOptionsForTextbook = /* GraphQL */ `
  query getOptionsForTextbook($jukuId: Int!, $studentId: Int!) {
    getOptionsForTextbook(jukuId: $jukuId, studentId: $studentId) {
      studentGrades {
        id
        grade
        gradeOrder
        semesters {
          id
          semesterName
        }
      }
      subjectAreas {
        id
        subjectAreaName
        subjects {
          id
          subjectName
        }
      }
      progressUnitTemplates {
        id
        progressUnitName
        templateOrder
      }
    }
  }
`

// aimの学習履歴の情報
export const getStudyHistoriesByStudentId = /* GraphQL */ `
  query getStudyHistoriesByStudentId($after: Int, $first: Int!, $studentId: Int!) {
    getStudyHistoriesByStudentId(after: $after, first: $first, studentId: $studentId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      studyHistories {
        __typename
        # StudyInfoUnion
        ... on LectureStudyInfo {
          studyInfoId
          pauseFlag
          startedDate
          finishedDate
          answerTime
          contents {
            __typename
            # 教科書レクチャーコンテンツ
            ... on TextbookLectureContents {
              id
              contentsId
              textbookContentsId
              contentsName
              path
            }
            # レクチャーコンテンツ
            ... on NormalLectureContents {
              id
              contentsName
              contentsId
              path
            }
          }
        }
        # StudyInfoUnion
        ... on OutputStudyInfo {
          studyInfoId
          pauseFlag
          startedDate
          finishedDate
          answerTime
          startedDate
          finishedDate
          # 正答数
          correctCount
          # 問題数
          questionCount
          exerciseQuestionCount
          # 正答率
          # テストの合計点数
          perfectScore
          # 自分の点数
          totalPoint
          answerTime
          descriptionTime
          leftQuestionCount
          contents {
            __typename
            # 教科書一問一答コンテンツ
            ... on TextbookOnetoContents {
              id
              # なんかとれない
              # normalContentsId
              contentsName
              onetoType
              path
            }
            #  教科書テストコンテンツ
            ... on TextbookTestContents {
              id
              textbookContentsId
              contentsName
              resultType
              path
              perfectScore
            }
            # 一問一答コンテンツ
            ... on NormalOnetoContents {
              id
              normalContentsId
              contentsName
              onetoType
              path
            }
            # テストコンテンツ
            ... on NormalTestContents {
              id
              normalContentsId
              contentsName
              resultType
              path
              perfectScore
            }
            # ユーザーテストコンテンツ
            ... on UserTestContents {
              userContentsId
              contentsName
              path
              resultType
              todoInfo {
                id
                createdDate
              }
            }
            # ユーザー一問一答コンテンツ
            ... on UserOnetoContents {
              userContentsId
              contentsName
              path
              onetoType
              todoInfo {
                id
                createdDate
              }
            }
          }
        }
      }
    }
  }
`

export const getGuidanceDatesCompletedReportByStudentId = /* GraphQL */ `
  query getGuidanceDatesCompletedReportByStudentId($studentId: Int!) {
    getGuidanceDatesCompletedReportByStudentId(studentId: $studentId) {
      date
      guidanceKomas {
        id
        komaTemplate {
          komaName
        }
        subjects {
          subjectName
        }
        guidanceReport {
          id
          parentReadGuidanceReport {
            readDate
          }
        }
      }
      loginInfos {
        loggedInAt
        loggedOutAt
      }
    }
  }
`
// TODO: 教材スピード 新スキーマ対応
// export const getCompletedGuidanceReportByGuidanceKomaId = /* GraphQL */ `
//   query getCompletedGuidanceReportByGuidanceKomaId($guidanceKomaId: Int!) {
//     getCompletedGuidanceReportByGuidanceKomaId(guidanceKomaId: $guidanceKomaId) {
//       id
//       completedDate
//       guidanceLoginInfos {
//         loggedInAt
//         loggedOutAt
//       }
//       parentReadGuidanceReport {
//         readDate
//       }
//       guidanceKoma {
//         komaDate
//         id
//         attendanceStatus
//         komaTemplate {
//           startedTime
//           finishedTime
//           komaName
//           komaOrder
//         }
//         subjects {
//           id
//           subjectName
//         }
//         managementUser {
//           __typename
//           ... on Teacher {
//             id
//             username
//             name
//           }
//           ... on Manager {
//             id
//             username
//             name
//           }
//         }
//         classroom {
//           id
//           classroomName
//         }
//       }
//       guidanceReviews {
//         id
//         score
//         guidanceReviewMaster {
//           id
//           itemName
//           perfectScore
//         }
//       }
//       parentReport {
//         id
//         content
//       }
//       classroomReport {
//         id
//         content
//       }
//       guidanceTests {
//         ... on GuidanceJukuTest {
//           id
//           testResult
//           score
//           perfectScore
//           guidanceReportId
//           retestFlag
//           guidanceJukuTestTemplate {
//             id
//             testName
//             pathItems {
//               id
//               folderName
//               parentFolderId
//             }
//           }
//         }
//         ... on GuidanceCustomTest {
//           id
//           score
//           testName
//           perfectScore
//           guidanceReportId
//           subject {
//             id
//             subjectName
//             categoryTypes {
//               guidance {
//                 id
//               }
//             }
//           }
//         }
//       }
//       guidanceKomaTextbookStudies {
//         id
//         studyMinutes
//         studentTextbook {
//           id
//           subject {
//             id
//             subjectName
//             subjectArea {
//               id
//               subjectAreaName
//             }
//           }
//           importantFlag
//           textbookName
//           studentGrade {
//             id
//             grade
//             gradeOrder
//           }
//           semester {
//             id
//             semesterName
//           }
//           # 進捗
//           targetLap
//           progressUnitName
//           # TODO:
//           # 全体進捗
//           totalTextbookStudyRanges {
//             startValue
//             endValue
//           }
//           textbookStudyComposition {
//             totalTextbookStudy {
//               id
//               totalTextbookProgresses {
//                 progressUnit {
//                   value
//                 }
//                 progressLap
//                 progressOwner
//               }
//             }
//           }
//           schoolLessonProgresses {
//             progressUnit {
//               value
//             }
//             isFinishedSchoolLesson
//           }
//         }
//         # コマ進捗
//         guidanceProgresses {
//           progressUnit {
//             value
//           }
//           progressLap
//           progressOwner
//         }
//       }
//       aimAtReports {
//         id
//         guidanceReportId
//         studyInfoUnion {
//           __typename
//           # StudyInfoUnion
//           ... on LectureStudyInfo {
//             studyInfoId
//             pauseFlag
//             startedDate
//             finishedDate
//             answerTime
//             contents {
//               __typename
//               # 教科書レクチャーコンテンツ
//               ... on TextbookLectureContents {
//                 id
//                 contentsId
//                 textbookContentsId
//                 contentsName
//                 path
//               }
//               # レクチャーコンテンツ
//               ... on NormalLectureContents {
//                 id
//                 contentsName
//                 contentsId
//                 path
//               }
//             }
//           }
//           # StudyInfoUnion
//           ... on OutputStudyInfo {
//             studyInfoId
//             pauseFlag
//             startedDate
//             finishedDate
//             answerTime
//             # 正答数
//             correctCount
//             # 問題数
//             questionCount
//             exerciseQuestionCount
//             descriptionTime
//             leftQuestionCount
//             contents {
//               __typename
//               # 教科書一問一答コンテンツ
//               ... on TextbookOnetoContents {
//                 id
//                 # なんかとれない
//                 # normalContentsId
//                 contentsName
//                 onetoType
//                 path
//               }
//               #  教科書テストコンテンツ
//               ... on TextbookTestContents {
//                 id
//                 textbookContentsId
//                 contentsName
//                 perfectScore
//                 resultType
//                 path
//               }
//               # 一問一答コンテンツ
//               ... on NormalOnetoContents {
//                 id
//                 normalContentsId
//                 contentsName
//                 onetoType
//                 path
//               }
//               # テストコンテンツ
//               ... on NormalTestContents {
//                 id
//                 normalContentsId
//                 contentsName
//                 resultType
//                 path
//               }
//               # ユーザーテストコンテンツ
//               ... on UserTestContents {
//                 userContentsId
//                 contentsName
//                 path
//                 resultType
//                 todoInfo {
//                   id
//                   createdDate
//                 }
//               }
//               # ユーザー一問一答コンテンツ
//               ... on UserOnetoContents {
//                 userContentsId
//                 contentsName
//                 path
//                 onetoType
//                 todoInfo {
//                   id
//                   createdDate
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export const getContractedKomaSubjectsByDateRange = /* GraphQL */ `
  query getContractedKomaSubjectsByDateRange(
    $studentId: Int!
    $dateRanges: [GetContractedKomaSubjectsByDateRangeArgsDateRange!]!
  ) {
    getContractedKomaSubjectsByDateRange(studentId: $studentId, dateRanges: $dateRanges) {
      id
      subjectName
      subjectArea {
        id
        subjectAreaName
      }
      categoryTypes {
        textbook {
          id
        }
        guidance {
          id
        }
        exam {
          id
        }
      }
    }
  }
`

/**
 * 学習計画一覧 総合の学習計画を取得
 */
export const getStudyPlan = /* GraphQL */ `
  query getStudyPlan($studentGradeId: Int!, $semesterId: Int!, $fiscalYear: Int!, $studentId: Int!) {
    getStudyPlan(
      studentGradeId: $studentGradeId
      semesterId: $semesterId
      fiscalYear: $fiscalYear
      studentId: $studentId
    ) {
      id
      studyPlanProgressSummary {
        totalRateOfProgress
        studyPlanProgressSummarySubjects {
          subjectArea {
            id
            subjectAreaName
          }
          rateOfProgress
        }
      }
      studyPlanPeriods {
        id
        studyPlanId
        startDate
        endDate
      }
      schoolExam {
        id
        examName
        examStartDate
        examEndDate
        studentGrade {
          id
        }
        semester {
          id
        }
      }
    }
  }
`
/**
 * 学習計画一覧 週毎の学習計画を取得
 * // TODO: 教材スピード 新スキーマ対応
 */
// export const getWeeklyStudyPlans = /* GraphQL */ `
//   query getWeeklyStudyPlans($studentId: Int!, $studentGradeId: Int!, $semesterId: Int!, $fiscalYear: Int!) {
//     getWeeklyStudyPlans(
//       studentId: $studentId
//       studentGradeId: $studentGradeId
//       semesterId: $semesterId
//       fiscalYear: $fiscalYear
//       # TODO: ページネーション対応 (初回リリース時は必須ではない)
//       first: 100
//       after: null
//     ) {
//       weeklyStudyPlans {
//         id
//         startDate
//         endDate
//         leftWeekCountsForExam
//         completedWeeklyStudyProgressRate
//         groupedWeeklyStudyPlanTextbooksBySubjectsAreas {
//           subjectArea {
//             id
//             subjectAreaName
//           }
//           groupedWeeklyStudyPlanTextbooks {
//             studyPlanSubjectInfo {
//               id
//               displayedSubjectName
//               examSubject {
//                 subject {
//                   id
//                 }
//               }
//             }
//             weeklyStudyPlanTextbooks {
//               studentTextbook {
//                 id
//                 progressUnitName
//                 textbookId
//                 textbookName
//                 importantFlag
//                 textbookTags {
//                   id
//                   tagName
//                   color
//                 }
//               }
//               studyPlanRanges {
//                 startValue
//                 endValue
//               }
//               lastStudiedProgressUnitValue
//               completedCurrentWeekStudyRate
//               completedStudyRateUntilLastWeek
//               isExpired
//             }
//           }
//         }
//       }
//     }
//   }
// `

/**
 * 学習計画一覧 週の学習計画詳細を取得
 * // TODO: 教材スピード 新スキーマ対応
 */
// export const getWeeklyStudyPlan = /* GraphQL */ `
//   query getWeeklyStudyPlan($weeklyStudyPlanId: Int!) {
//     getWeeklyStudyPlan(weeklyStudyPlanId: $weeklyStudyPlanId) {
//       id
//       startDate
//       endDate
//       guidanceDates {
//         exams {
//           __typename
//         }
//         date
//         guidanceKomas {
//           id
//           attendanceStatus
//           subjects {
//             id
//             subjectName
//             __typename
//           }
//           komaTemplate {
//             komaName
//             __typename
//           }
//           __typename
//         }
//         __typename
//       }
//       leftWeekCountsForExam
//       weeklyStudyPlanMemo {
//         memo
//         lastUpdatedBy {
//           name
//           __typename
//         }
//         updatedDate
//         __typename
//       }
//       groupedWeeklyStudyPlanTextbooksBySubjectsAreas {
//         subjectArea {
//           id
//           subjectAreaName
//           __typename
//         }
//         groupedWeeklyStudyPlanTextbooks {
//           studyPlanSubjectInfo {
//             id
//             displayedSubjectName
//             examSubject {
//               subject {
//                 id
//                 __typename
//               }
//               __typename
//             }
//             __typename
//           }
//           weeklyStudyPlanTextbooks {
//             studentTextbook {
//               id
//               progressUnitName
//               textbookId
//               textbookName
//               importantFlag
//               textbookTags {
//                 id
//                 tagName
//                 color
//                 __typename
//               }
//               __typename
//             }
//             studyPlanRanges {
//               startValue
//               endValue
//               __typename
//             }
//             lastStudiedProgressUnitValue
//             completedCurrentWeekStudyRate
//             completedStudyRateUntilLastWeek
//             isExpired
//             __typename
//           }
//           __typename
//         }
//         __typename
//       }
//       __typename
//     }
//   }
// `

/**
 * 学習計画/学年学期情報
 */
export const getOptionsForStudyPlan = /* GraphQL */ `
  query getOptionsForStudyPlan($studentId: Int!) {
    getOptionsForStudyPlan(studentId: $studentId) {
      studentGrades {
        id
        grade
        gradeOrder
        semesters {
          semesterName
          semesterOrder
          id
        }
      }
    }
  }
`

/**
 * コマテンプレート取得
 */
export const getKomaTemplatesByClassroomId = /* GraphQL */ `
  query getKomaTemplatesByClassroomId($classroomId: Int!) {
    getKomaTemplatesByClassroomId(classroomId: $classroomId) {
      id
      jukuId
      komaId
      komaName
      komaOrder
      startedTime
      finishedTime
      komaKind {
        id
        komaKindName
        komaKindOrder
        color
      }
      parentsKomaRequestDeadline {
        daysToDeadline
        timeDeadline
      }
    }
  }
`

/**
 * コマ一覧取得（生徒）
 */
export const getGuidanceDatesByStudentId = /* GraphQL */ `
  query getGuidanceDatesByStudentId($studentId: Int!, $startDate: String!, $endDate: String!) {
    getGuidanceDatesByStudentId(studentId: $studentId, startDate: $startDate, endDate: $endDate) {
      date
      guidanceKomas {
        id
        guidanceReport {
          completedDate
        }
        komaDate
        subjects {
          id
          subjectName
          subjectOrder
        }
        komaTemplate {
          id
          komaName
          startedTime
          finishedTime
          komaKind {
            id
            komaKindName
            komaKindOrder
            color
          }
          parentsKomaRequestDeadline {
            daysToDeadline
            timeDeadline
          }
        }
        attendanceStatus
        transferStartedDate
        transferFinishedDate
        transferOriginGuidanceKoma {
          komaDate
          komaTemplate {
            komaName
          }
        }
      }
    }
  }
`

/**
 * コマ一覧取得（教室）
 */
export const getClassroomGuidanceDatesByDateRange = /* GraphQL */ `
  query getClassroomGuidanceDatesByDateRange($classroomId: Int!, $startDate: String!, $endDate: String!) {
    getClassroomGuidanceDatesByDateRange(classroomId: $classroomId, startDate: $startDate, endDate: $endDate) {
      date
      classroomKomas {
        komaDate
        id
        komaTemplate {
          id
          komaName
          startedTime
          finishedTime
          komaKind {
            id
            komaKindName
            komaKindOrder
            color
          }
          parentsKomaRequestDeadline {
            daysToDeadline
            timeDeadline
          }
        }
        parentKomaRequestable
        transferStartedDate
        transferFinishedDate
      }
    }
  }
`
/**
 * 教科・科目の取得
 */
export const getOptionsForGuidanceKoma = /* GraphQL */ `
  query getOptionsForGuidanceKoma($jukuId: Int!, $classroomId: Int!) {
    getOptionsForGuidanceKoma(jukuId: $jukuId, classroomId: $classroomId) {
      subjectAreas {
        id
        subjectAreaName
        subjects {
          id
          subjectName
          categoryTypes {
            guidance {
              id
            }
          }
        }
      }
    }
  }
`

/**
 * 指導報告保護者既読情報取得
 */
export const getParentReadGuidanceReports = /* GraphQL */ `
  query getParentReadGuidanceReports($parentId: Int!) {
    getParentReadGuidanceReports(parentId: $parentId) {
      guidanceReportId
      readDate
    }
  }
`
