import { gql } from '@apollo/client/core'
import { apolloClient, ERROR_NOT_FOUND } from '@/apis/graphql/client'
import { getParentReadGuidanceReports } from '@/apis/graphql/query/queries'
import {
  type BulkRegisterParentReadGuidanceReportsMutation,
  type GetParentReadGuidanceReportsQuery,
  type GetParentReadGuidanceReportsResponse,
  type ParentReadGuidanceReportsBulkRegisterInput
} from '@/types/codegen/schema'
import { bulkRegisterParentReadGuidanceReports } from '@/apis/graphql/query/mutations'

export namespace ParentReadGuidanceReportRepoResponse {
  export type fetchByParentId = GetParentReadGuidanceReportsResponse
}

export const parentReadGuidanceReportRepo = {
  fetchByParentId: async (parentId: number) => {
    const { data } = await apolloClient.query<GetParentReadGuidanceReportsQuery>({
      query: gql(getParentReadGuidanceReports),
      variables: { parentId }
    })
    if (!data || !data.getParentReadGuidanceReports) throw ERROR_NOT_FOUND
    console.debug('getParentReadGuidanceReports data', data.getParentReadGuidanceReports)
    return data.getParentReadGuidanceReports
  },
  bulkRead: async (input: ParentReadGuidanceReportsBulkRegisterInput) => {
    console.debug('bulkRegisterParentReadGuidanceReports input', input)
    const { data } = await apolloClient.mutate<BulkRegisterParentReadGuidanceReportsMutation>({
      mutation: gql(bulkRegisterParentReadGuidanceReports),
      variables: { input }
    })
    if (!data || !data.bulkRegisterParentReadGuidanceReports) throw ERROR_NOT_FOUND
    console.debug('bulkRegisterParentReadGuidanceReports data', data.bulkRegisterParentReadGuidanceReports)
    return data.bulkRegisterParentReadGuidanceReports
  }
}
