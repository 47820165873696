import { render, staticRenderFns } from "./SideMenuPc.vue?vue&type=template&id=c5293582"
import script from "./SideMenuPc.vue?vue&type=script&lang=ts"
export * from "./SideMenuPc.vue?vue&type=script&lang=ts"
import style0 from "./SideMenuPc.vue?vue&type=style&index=0&id=c5293582&prod&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports