
import {
  computed,
  defineComponent,
  provide,
  ref,
  useContext,
  useFetch,
  useRoute,
  useRouter
} from '@nuxtjs/composition-api'
import type { InjectionKey, Ref } from 'vue'
import { type ModulesState } from '../store/userStateTypes'
import { useStore } from '@/useCases/useStore'
import {
  parentReadNotificationRepo,
  type ParentReadNotificationRepoResponse
} from '@/repos/notifications/parentReadNotification'
import { useSnackbar, SnackbarKey } from '@/useCases/snackBar'
import { studentRepo, type StudentRepoResponse } from '@/repos/student'
import {
  type ParentReadGuidanceReportRepoResponse,
  parentReadGuidanceReportRepo
} from '@/repos/parentReadGuidanceReport'

export const NotificationsInjectionKey: InjectionKey<Ref<ParentReadNotificationRepoResponse.fetchByParentId>> =
  Symbol('NotificationsInjectionKey')
export const ParentReadGuidanceReportsInjectionKey: InjectionKey<
  Ref<ParentReadGuidanceReportRepoResponse.fetchByParentId>
> = Symbol('ParentReadGuidanceReportsInjectionKey')

export interface SideNavOption {
  icon: string
  title: string
  to: string
  badgeContent?: number
}

const SIDE_MENU_OPTIONS_MOBILE: ReadonlyArray<SideNavOption> = [
  { icon: 'mdi-file-chart-outline', title: '成績', to: '/grades' },
  { icon: 'mdi-book-open-blank-variant', title: '教材', to: '/materials' },
  { icon: 'mdi-account-circle-outline', title: 'アカウント', to: '/account' }
] as const

export default defineComponent({
  setup() {
    const { $vuetify, i18n } = useContext()
    const route = useRoute()
    const router = useRouter()
    const store = useStore<ModulesState>()
    const snackbarCtx = useSnackbar()

    const student = ref<StudentRepoResponse.FetchDetail | null>(null)
    const notifications = ref<ParentReadNotificationRepoResponse.fetchByParentId>([])
    const parentReadGuidanceReports = ref<ParentReadGuidanceReportRepoResponse.fetchByParentId>([])

    const user = computed(() => store.state.user)

    const sideMenuOptionsPc = computed<SideNavOption[]>(() => {
      const unreadNotifications = notifications.value.filter(notification => !notification.readDate)
      const unreadGuidanceReports = parentReadGuidanceReports.value.filter(item => !item.readDate)
      return [
        ...(store.state.jukuConfig.parentContactFlag
          ? [{ icon: 'mdi-home', title: 'ホーム', to: '/' }]
          : ([] as SideNavOption[])),
        {
          icon: 'mdi-clock-outline',
          title: 'タイムライン',
          to: '/timeline',
          badgeContent: unreadGuidanceReports.length
        },
        {
          icon: 'mdi-bell-outline',
          title: 'おしらせ',
          to: '/notifications',
          badgeContent: unreadNotifications.length
        },
        { icon: 'mdi-calendar', title: '指導', to: '/calendar' },
        { icon: 'mdi-file-chart-outline', title: '成績', to: '/grades' },
        { icon: 'mdi-book-open-blank-variant', title: '教材', to: '/materials' },
        { icon: 'mdi-account-circle-outline', title: 'アカウント', to: '/account' }
      ]
    })

    const idShowedBrowserBackButton = computed(
      () =>
        (route.name?.includes('id') ||
          route.name?.includes('Id') ||
          route.name?.includes('account-edit') ||
          route.name?.includes('room-access-qr')) &&
        $vuetify.breakpoint.mobile
    )
    const toPreviousPage = () => router.back()

    const layoutOptions = computed(() =>
      $vuetify.breakpoint.mobile ? SIDE_MENU_OPTIONS_MOBILE : sideMenuOptionsPc.value
    )
    const isOpenedSideMenu = ref(false)

    const title = computed(() => {
      const routeName = i18n.messages.ja.routeName
      return Object.keys(routeName).includes(route.name || '') ? i18n.tc(`routeName.${route.name}`) : ''
    })

    /**
     * init
     */
    useFetch(async () => {
      ;[student.value, notifications.value, parentReadGuidanceReports.value] = await Promise.all([
        studentRepo.fetchDetail(user.value.studentId),
        parentReadNotificationRepo.fetchByParentId(user.value.id),
        parentReadGuidanceReportRepo.fetchByParentId(user.value.id)
      ])
    })

    /**
     * フッターの未読バッジの表示と更新で必要なのでdefault.vueからprovideする
     */
    provide(NotificationsInjectionKey, notifications)
    provide(ParentReadGuidanceReportsInjectionKey, parentReadGuidanceReports)
    provide(SnackbarKey, snackbarCtx)

    return {
      layoutOptions,
      title,
      isOpenedSideMenu,
      ...snackbarCtx,
      idShowedBrowserBackButton,
      toPreviousPage,
      student
    }
  }
})
