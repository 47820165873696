
import { defineComponent, useRouter } from '@nuxtjs/composition-api'
import type { PropType } from 'vue'
import { useStore } from '@/useCases/useStore'
import { type SideNavOption } from '@/parent/layouts/default.vue'

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<ReadonlyArray<SideNavOption>>,
      required: true
    }
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const signOut = async () => {
      try {
        await store.dispatch('user/signOut')
        await router.push('/sign-in')
      } catch (err) {
        console.error(err)
      }
    }

    return { signOut }
  }
})
