import type { MutationTree, ActionTree } from 'vuex'
import { type State, type ConfirmContactState } from '@/parent/store/userStateTypes'
/**
 * お問い合わせフォームで内容確認ページに遷移する際に入力を保存しておくため.
 * pagesのファイル構成変えてまでpropsやprovideにしなくてもいいかなと思った.
 */
export const state = (): ConfirmContactState => ({
  email: {
    id: -1,
    emailAddress: ''
  },
  classroom: {
    id: -1,
    classroomName: ''
  },
  content: '',
  subject: ''
})

export const mutations: MutationTree<ConfirmContactState> = {
  set(s, user) {
    Object.assign(s, { ...user })
  },
  reset(s) {
    Object.assign(s, state())
  }
}

export const actions: ActionTree<ConfirmContactState, State> = {
  set({ commit }, payload) {
    commit('set', payload)
  },
  reset({ commit }) {
    commit('reset')
  }
}
