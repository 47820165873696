
import { computed, defineComponent, inject } from '@nuxtjs/composition-api'
import { NotificationsInjectionKey, ParentReadGuidanceReportsInjectionKey } from '../layouts/default.vue'
import { type ModulesState } from '../store/userStateTypes'
import { useStore } from '@/useCases/useStore'

type FooterItem = {
  icon: string
  title: string
  to: string
  badgeContent?: number
}

export default defineComponent({
  setup() {
    const notifications = inject(NotificationsInjectionKey)!
    const parentReadGuidanceReports = inject(ParentReadGuidanceReportsInjectionKey)!

    const store = useStore<ModulesState>()

    const footerItems = computed<FooterItem[]>(() => {
      const unreadNotifications = notifications.value.filter(notification => !notification.readDate)
      const unreadGuidanceReports = parentReadGuidanceReports.value.filter(item => !item.readDate)
      return [
        ...(store.state.jukuConfig.parentContactFlag
          ? [{ icon: 'mdi-home', title: 'ホーム', to: '/' }]
          : ([] as FooterItem[])),
        {
          icon: 'mdi-clock-outline',
          title: 'タイムライン',
          to: '/timeline',
          badgeContent: unreadGuidanceReports.length
        },
        {
          icon: 'mdi-bell-outline',
          title: 'おしらせ',
          to: '/notifications',
          badgeContent: unreadNotifications.length
        },
        { icon: 'mdi-calendar', title: '指導', to: '/calendar' }
      ]
    })

    return { footerItems }
  }
})
