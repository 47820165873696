import type { MutationTree, ActionTree } from 'vuex'
import { dayjs } from '@/plugins/dayjs'
import { type State } from '@/parent/store/userStateTypes'
import { type GetJukuConfigByJukuIdResponse } from '@/types/codegen/schema'
import { DATE_FORMATS } from '@/modules/util'

export const state = (): GetJukuConfigByJukuIdResponse => ({
  __typename: 'JukuConfig',
  id: -1,
  jukuId: -1,
  currentFiscalYear: parseInt(dayjs().format(DATE_FORMATS.year)),
  promotionPlanDate: '',
  lineLinkFlag: false,
  parentContactFlag: false
})

export const mutations: MutationTree<GetJukuConfigByJukuIdResponse> = {
  set(s, jukuConfig) {
    Object.assign(s, { ...jukuConfig })
  }
}

export const actions: ActionTree<GetJukuConfigByJukuIdResponse, State> = {
  set({ commit }, data) {
    commit('set', data)
  },
  reset({ commit }) {
    commit('set', {
      __typename: 'JukuConfig',
      id: -1,
      jukuId: -1,
      currentFiscalYear: parseInt(dayjs().format(DATE_FORMATS.year)),
      promotionPlanDate: '',
      lineLinkFlag: 0,
      parentContactFlag: 0
    })
  }
}
